import {
  EventAvailable,
  Grading,
  History,
} from "@mui/icons-material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ActionButton } from "../../components/button/SubmitButton";
import { USER_ROLES } from "../../utils/constants";

const LeaveBtnBox = {
  width: "100%",

  display: "flex",
};

const EmployeeTaskReviewButtons = ({ userId }) => {
  const location = useLocation();
  const pathArr = location.pathname.split("/");
  const slug = pathArr[pathArr.length - 1];
  const user = useSelector((state) => state.user);
  console.log("object user", user);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  // if(desktop)
  return (
    <>
      <Box sx={LeaveBtnBox} mt={5} mb={5}>
        <ActionButton
          active={slug == "Employee-daily-update"}
          sx={{ borderRadius: "4px" }}
          LinkComponent={Link}
          to="Employee-daily-update"
          title={"Employee Daily Update"}
          icon={<History />}
        ></ActionButton>
        <Box ml={2} />

        <ActionButton
          active={slug == "Employee-review-form"}
          sx={{ borderRadius: "4px" }}
          LinkComponent={Link}
          to="Employee-review-form"
          title="Employee Review Form"
          icon={<Grading />}
        ></ActionButton>

        <Box ml={2} />
        <ActionButton
          isFirst={true}
          LinkComponent={Link}
          active={slug == "" || slug == "Employee-Task-calendar"}
          to="Employee-Task-calendar"
          sx={{ borderRadius: "4px" }}
          title={"Employee Work Calendar"}
          icon={<EventAvailable />}
        ></ActionButton>
        <Box ml={2} />

        {/* {user?.data?.role === USER_ROLES.hr &&  */}
        <ActionButton
          isFirst={true}
          LinkComponent={Link}
          active={slug == "" || slug == "Project-Analytical-Report"}
          to="Project-Analytical-Report"
          sx={{ borderRadius: "4px" }}
          title={"Monthly Employee Task report"}
          icon={<AssessmentIcon />}
        ></ActionButton>
        {/* //  } */}
      </Box>
    </>
  );
};
export default EmployeeTaskReviewButtons;
