import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'
import ProjectListUi from './ProjectListUi'
import {
    createHoliday,
    fetchHolidays,
    deleteHoliday,
} from '../../../apis/holiday.api'
import moment from 'moment'
// import DeleteHolidayButton from './DeleteHolidayButton'
import { fetchHolidayDataAction } from '../../../store/actions/settingsAction'
import { Delete, Edit } from '@mui/icons-material'
import { deleteProjects, fetchProjects } from '../../../apis/project.api'
import { IconButton, Typography } from '@mui/material'
import { closeModal, openModal } from '../../../store/actions/modalAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import CreateProjectController from './CreateProjectController'


const ActionButton = ({ params, setParams, date }) => {
    console.log("KIKIKI", params);
    const [loading, setLoading] = useState();
    const dispatch = useDispatch();


    const onDelete = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () =>
                    await deleteProjects({
                        id: params._id,
                    }),
                (response) => {
                    setLoading(false);
                    setParams({});
                    dispatch(
                        callSnackBar(
                            "Project Deleted",
                            SNACK_BAR_VARIETNS.suceess
                        )
                    );
                },
                (err) => {
                    setLoading(false);
                    dispatch(
                        callSnackBar("Can not delete this project", SNACK_BAR_VARIETNS.error)
                    );
                }
            )
        );
        dispatch(closeModal());
    };
    const onDltBtnCLick = () => {
        dispatch(
            openModal({
                title: "Alert!",
                component: (
                    <Typography>
                        {"Are you sure that you want to delete this project"}
                    </Typography>
                ),
                onConfirm: onDelete,
                size: "sm",
                confirmText: "Delete",
                onCancle: () => dispatch(closeModal()),
            })
        );
    };


    const onEdit = () => {
        dispatch(openModal({
            title: "Update Project",
            component: <CreateProjectController id={params._id} />, size: 'sm'
        }))
    };
    return (
        <>
            <IconButton onClick={onDltBtnCLick}>
                <Delete color="error" />
            </IconButton>
            <IconButton size="inherit" onClick={onEdit}>
                <Edit color="info" fontSize="inherit" />
            </IconButton>
        </>
    );
};

const ProjectListController = ({ userId }) => {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)

    const { settings } = useSelector((state) => state)


    const columns = useMemo(() => [
        { id: 1, fieldName: 'department', label: 'Departments', align: "left", sort: true, minWidth: '150px', renderValue: (params, setParams) => params?.department?.map((dept) => dept.name).join(", ") },
        { id: 2, fieldName: 'project_name', label: 'Projects', align: "left", sort: true, minWidth: '150px', renderValue: (params, setParams) => params?.project_name },
        {
            id: 3, fieldName: 'delete', label: 'Action', align: "left", minWidth: '150px', renderValue: (params, setParams) =>
                <ActionButton
                    // key={date}
                    // date={date}
                    params={params}
                    setParams={setParams}
                />
        },
    ], [dispatch]);

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: "",
        searchable: ["name"],
        sort: "",
        sortDirection: -1,
        name: "",
    });

    const fetchHolidaysApi = fetchHolidays

    const fetchList = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await fetchProjects({}),
                (response) => {
                    setState(response)
                    console.log("object response", response);
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                },
            ),
        )
    }

    useEffect(() => {
        fetchList()
    }, [])

    return (
        <ProjectListUi
            setState={setState}
            loading={loading}
            state={state}
            filters={filters}
            setFilters={setFilters}
            columns={columns}
        />
    )
}
export default ProjectListController