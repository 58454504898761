import {
  Autocomplete,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import { StyledSearchBar } from "../../components/inputs/SearchBar";

import { USER_ROLES } from "../../utils/constants";
import DataTable from "../../components/tables/DataTable";
import { fetchDepartments } from "../../apis/department";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import CustomInput from "../../components/inputs/CustomInput";

const UserPageUi = ({
  listLoading,
  filters,
  setFilters,
  list,
  columns,
  onCreateBtnClick,
  fetchList,
  exportLoading,
  MultipleDepartment
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box mt={3} mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box
            mb={4}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">Users</Typography>
            <Box
              pl={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {user.data.role == USER_ROLES.hr && <Box mr={2} width={"15vw"}>
                <AsyncDropDown
                  defaultVal={
                    filters.department
                      ? {
                        _id: filters.department,
                        name: filters.departmentName || "",
                      }
                      : null
                  }
                  InputComponent={(props) => (
                    <CustomInput
                      label="Department"
                      placeholder="Select Department"
                      {...props}
                    />
                  )}
                  label="Select Department*"
                  lazyFun={async (props) => {
                    return await fetchDepartments({ ...props });
                  }}
                  OptionComponent={({ option, ...rest }) => (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      {...rest}
                    >
                      <Typography ml={3} variant="h5">
                        {option?.name}
                      </Typography>
                    </Box>
                  )}
                  value={filters.department}
                  onChange={async (changedVal) => {
                    setFilters({ ...filters, department: changedVal ? changedVal?._id : null });
                  }}
                  titleKey="name"
                  valueKey="_id"
                />

              </Box>}

              <Box>
                <StyledSearchBar
                  placeholder={"Search Name | Email | Usercode"}
                  value={filters.search}
                  onChange={(e) => {
                    setFilters({ ...filters, search: e.target.value });
                  }}
                />
              </Box>

              {user.data &&
                (user.data.role === USER_ROLES.admin ||
                  user.data.role === USER_ROLES.hr) && (
                  <>
                    <Box pl={2} >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e, newVal) => {
                          setFilters({
                            ...filters,
                            role: parseInt(newVal._id),
                          });
                        }}
                        options={[
                          { label: "All", _id: null },
                          ...Object.keys(USER_ROLES).map((key) => ({
                            label: key,
                            _id: USER_ROLES[key],
                          })),
                        ]}
                        sx={{
                          width: "10vw",
                          height: "7vh",
                          display: "flex",
                          "*": { display: "flex", justifyContent: "center" },
                        }}
                        renderInput={(params) => (
                          <StyledSearchBar
                            placeholder="Select Role"
                            {...params}
                          />
                        )}
                      />
                    </Box>

                    <Box pl={2} sx={{ display: "flex" }}>
                      <SubmitButton
                        variant="contained"
                        onClick={onCreateBtnClick}
                        title={"Create User"}
                      />
                    </Box>
                  </>
                )}
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex" }} mt={2} mb={4}>
              {user.data.role != USER_ROLES.employee && (
                <Box>
                  {" "}
                  <SubmitButton
                    loading={exportLoading}
                    variant="contained"
                    onClick={() => fetchList(true)}
                    title={"Export"}
                  />
                </Box>

              )}
              {user.data &&
                (user.data.role === USER_ROLES.admin ||
                  user.data.role === USER_ROLES.hr) && (<Box ml={2}>
                    {" "}
                    <SubmitButton
                      variant="contained"
                      onClick={MultipleDepartment}
                      title={"Add Secondary Department"}
                    />
                  </Box>)}
            </Box>

            <Box pl={2} >

            </Box>
            <Box
              mt={2}
              mb={4}
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
              }}
            // pl={3}
            >
              <ButtonGroup disableElevation variant="outlined">
                <Button
                  onClick={() => setFilters({ ...filters, enabled: null })}
                  variant={filters.enabled == null ? "contained" : "outlined"}
                >
                  Active User
                </Button>
                <Button
                  onClick={() => setFilters({ ...filters, enabled: false })}
                  variant={filters.enabled == false ? "contained" : "outlined"}
                >
                  Inactive User
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={listLoading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default UserPageUi;