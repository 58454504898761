import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  ListItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  createDailyUpdate,
  getProjectAnalysisData,
  getProjectDepartmentWise,
} from "../../../apis/dailyupdate.api";
import { closeModal } from "../../../store/actions/modalAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers";
import { Delete } from "@mui/icons-material";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import useValidate from "../../../store/hooks/useValidator";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import CustomInput from "../../../components/inputs/CustomInput";
import DurationPicker from "../../../components/layouts/common/CustomDurationPicker";
import getUserAndSystemDataApi from "../../../apis/user.api";

const DailyUpdateEditor = ({
  date,
  departmentId,
  secondaryParentId,
  callBack = () => { },
}) => {
  const dispatch = useDispatch();
  const validate = useValidate();

  const { user } = useSelector(state => state)
  console.log("user", user.data.isAccess)

  const [isLoading, setIsLoading] = useState(false);
  const [projectAnalysis, setProjectAnalysis] = useState([]);

  const [data, setData] = useState("");

  const [fields, setFields] = useState({
    err: "",
    date: date,
    department: departmentId,
    secondary_parentId: departmentId,
    project: "",
    time: null,
    description: "",
    isAccess: false
  });
  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setFields((prev) => ({ ...prev,err:"",description: data.trim() }));
  }, [data]);



  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: fields.description.trim(),
        field: "Daily Update Description",
      },

      {
        value: fields.project,
        field: "Project",
      },
      { 
        required:user.data.isAccess,
        value: fields.project,
        field: "Project",
      },
      { 
        required:user.data.isAccess,
        value: fields.time ? fields.time : 0,
        field: "Time",
      },
    ],
    [fields]
  );


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const validationResponse = validate(validationSchema);
console.log("object validationResponse", validationResponse);
    if (validationResponse === true) {
      const formattedDate = moment(fields.date).format("YYYY-MM-DD");

      const updatedFields = {
        ...fields,
        date: formattedDate,
      };


      await dispatch(
        callApiAction(
          async () => await createDailyUpdate(updatedFields),
          async (response) => {
            if (response) {
              callBack();
              dispatch(
                callSnackBar("Daily Update Added", SNACK_BAR_VARIETNS.suceess)
              );
              dispatch(closeModal());
              setFields({ ...fields });
            } else {
              dispatch(
                callSnackBar(
                  "Failed to add Daily Update",
                  SNACK_BAR_VARIETNS.error
                )
              );
            }
          },
          (err) => {
            setLoading(false);
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setLoading(false);

      setFields({ ...fields, 'err': validationResponse });
      // dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error));
    }
  }
  console.log("  user?.data?.isAccess", fields.time)


  return (
    <Box>
      <Box>
        <Typography variant="h4" color={"red"}>{fields.err}</Typography>
      </Box>
      <Box mt={3} p={2}>
        <Box>
          {user?.data?.isAccess === true &&
            <Box display="flex" gap={2} alignItems="center" width={"100%"}>
              <Box display="flex" gap={2} alignItems="center" width={"80%"}>
                <AsyncDropDown
                  defaultVal={
                    fields.project
                      ? {
                        _id: fields.project._id,
                        name: fields.project.project_name,
                      }
                      : null
                  }
                  lazyFun={async (para) =>
                    await getProjectDepartmentWise({
                      para,
                      department_id: departmentId,
                    })
                  }
                  OptionComponent={({ option, ...rest }) => {
                    return <ListItem {...rest}>{option.project_name}</ListItem>;
                  }}
                  value={fields.project}
                  onChange={async (changedVal) => {
                    setFields({
                      ...fields,
                      err:"",
                      project: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"project_name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <CustomInput
                      placeholder={"Select Project*"}
                      {...params}
                      margin="none"
                    />
                  )}
                />
              </Box>
              <Box width={"20%"}>
                <DurationPicker
                  value={fields.time || 0}
                  onChange={(newDuration) =>
                    setFields({ ...fields,err:"", time: newDuration })
                  }
                />
              </Box>
            </Box>
          }

          <Box display="flex" alignItems="flex-start" gap={2} mt={3}>
            <Box flex={1} minHeight={"30vh"}>
              <CKEditor
                disabled={loading}
                editor={ClassicEditor}
                style={{ minHeight: "500px" }}
                config={{
                  placeholder: "Add Update...",
                  style: { height: "500px", minHeight: "500px" },
                  toolbar: ["bulletedList", "numberedList"],
                }}
                data={data}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const newData = editor.getData();
                  setData(newData);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <Button
          disabled={loading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          fullWidth
        >
          {loading ? <CircularProgress size={24} /> : "Add"}
        </Button>
      </Box>
    </Box>
  );
};

export default DailyUpdateEditor;