import React, { useEffect, useState } from 'react'
import { callApiAction } from '../../store/actions/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import { fetchdocuments } from '../../apis/document.api'
import CompanypolicyUi from './CompanyPolicyUi'
import { UserPolicyLogUpdate } from '../../apis/user.api'
import { useNavigate } from 'react-router-dom'
import { fetchUserDetails } from '../../store/actions/userReducerAction'


const CompanypolicyController = () => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState([])
    const [policyButton, setPolicyButton] = useState();
    const [policyIdGet, setPolicyIdGet] = useState();
    const [topicindex, setTopicindex] = useState(0);
    const dispatch = useDispatch();
    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchdocuments(),
            async (response) => {
                const readPolicyIds = new Set(user?.data?.policy_logs?.map(log => log?.policy_id));
                const unreadPolicies = response?.result.filter(policy => !readPolicyIds.has(policy._id));
                setState(unreadPolicies)
                if (topicindex < response.length) {
                    setPolicyButton(response[topicindex]._id);
                }

                // setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const UpdatepolicyIdGet = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await UserPolicyLogUpdate({ policy_id: policyIdGet }),
            (response) => {
                // setLoading(false)
                setTopicindex(topicindex + 1);

                if (topicindex === state.length - 1) {
                    dispatch(fetchUserDetails());
                    navigate("/dashboard/");
                    // window.location.reload();
                }
            },
            (err) => {
                setLoading(false)
            }
        ))
    }


    useEffect(() => {
        if (policyIdGet) {
            UpdatepolicyIdGet()
        } else {
            fetchList();
        }

    }, [policyIdGet])

    return (
        <CompanypolicyUi
            state={state}
            policyButton={policyButton}
            setPolicyButton={setPolicyButton}
            policyIdGet={policyIdGet}
            setPolicyIdGet={setPolicyIdGet}
            loading={loading}
            setLoading={setLoading}
            topicindex={topicindex}
            setTopicindex={setTopicindex}
        />
    )
}

export default CompanypolicyController


