import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import AsyncDropDown from '../../components/inputs/AsyncDropDown';
import CustomInput from '../../components/inputs/CustomInput';
import { fetchDepartments } from '../../apis/department';
import chroma from 'chroma-js';
import { CenteredBox } from '../../components/layouts/common/boxes';
import { AccountCircle, Business, Padding } from '@mui/icons-material';
import { toTitleCase } from '../../utils/helper';
import Grid2 from '@mui/material/Unstable_Grid2';
import styled from '@emotion/styled';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { EmployeeTaskUserContext } from './EmployeeMain';
import DailyUpdateCustomDayPicker from "../../components/layouts/common/DailyUpdateCustomDayPicker";
import CustomizedNewMonthpicker from '../../components/layouts/common/CustomizedNewMonthpicker';
import CustomYearPicker from '../../components/layouts/common/CustomYearPicker';
import CustomMonthPicker from '../../components/layouts/common/CustomMonthPicker';


ChartJS.register(ArcElement, Tooltip, Legend);

const UserProjectsCard = (data, index, idx, formatTime, card, key) => {
  return (

    <Grid item xs={12} sm={6} md={4} key={key}>

      <Card
        sx={{
          height: "220px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "12px",
          border: "1px solid #ddd",
        }}
      >


        <CardContent >
          <Box mb={2} sx={{
            backgroundColor: "#f9f9f9",
            padding: "4px 8px",
            borderRadius: "4px",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
            height: "5vh",
            display: "flex",
            alignItems: "center"
          }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",


              }}
            >
              <AccountCircle sx={{ fontSize: 20, mr: 1 }} />
              {data?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              // minHeight: "20vh",
              maxHeight: "20vh",
              // minWidth: "20vw",
              overflowY: "auto",
              scrollbarColor: "#DDDDDD"
            }}
          >
            {(data?.projects?.length) > 0 && (card?.projectAnalysis?.length > 0) ? (<TableContainer component={Paper}>
              <Table stickyHeader size='small' >
                {/* Table Header */}
                <TableHead >
                  <TableRow >
                    <TableCell
                      sx={{
                        width: "50%",
                        backgroundColor: "#000",
                        color: "#fff",
                      }}
                    >
                      Project
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        backgroundColor: "#000",
                        color: "#fff"
                      }}
                    >
                      Hours Worked
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* Table Body */}
                <TableBody >
                  {data?.projects?.map((project, index) => (
                    <TableRow key={index} >
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        {project?.projectName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {formatTime(project?.totalTime)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#999",
                    mt: 2,
                  }}
                >
                  No Projects Found.
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>

  );
};


// const SelectedUserProjectsCard = (selectedUser, formatTime, selectedUserDailyUpdate, data, key, departments) => {
//   // const data? = data?.data?.map((item)=>item?.projectAnalysis)

//   return (

//     <Grid mt={4} item xs={12} sm={6} md={4} key={key}>

//       <Card
//         sx={{
//           height: "auto",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between",
//           borderRadius: "12px",
//           border: "1px solid #ddd",
//           width: "30vw"
//         }}
//       >


//         <CardContent >
//           <Box mb={2} sx={{
//             backgroundColor: "#f9f9f9",
//             padding: "4px 8px",
//             borderRadius: "4px",
//             boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
//             height: "7vh",
//             display: "flex",
//             alignItems: "flex-start",
//             justifyContent: "center",
//             flexDirection: "column",
//           }}>
//             <Box>
//               <Typography
//                 variant="h5"
//                 sx={{
//                   fontWeight: "bold",
//                   display: "flex",
//                   alignItems: "center",


//                 }}
//               >
//                 <AccountCircle sx={{ fontSize: 20, mr: 1 }} />
//                 {selectedUser?.name}
//               </Typography>
//             </Box>

//             <Box>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontWeight: "bold",
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//               >
//                 <Business color="primary" sx={{ fontSize: 22, mr: 1 }} />
//                 {selectedUser?.department}
//               </Typography>
//             </Box>
//           </Box>

//         </CardContent>
//       </Card>
//     </Grid>

//   );
// };


const EmployeeProjectAnalyticalReportUi = ({ fields, setFields, departmentData, dailyupdateWithDepartment, expanded, setExpanded, date, setDate, isMonth, setIsMonth, selectedUser, onExportClick, selectedUserDailyUpdate, departmentWiseId, loading, fetchMoreloading, departments }) => {

  const [userWisePieData, setUserWisePieData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Time (Hours)',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        offset: [],
      },
    ],
  });


  const handleChartHover = (event, elements) => {
    if (elements?.length > 0) {
      const index = elements[0].index;
      setUserWisePieData((prevData) => {
        const newOffset = prevData.datasets[0].offset.map((offset, i) => (i === index ? 40 : 0));
        return {
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              offset: newOffset,
            },
          ],
        };
      });
    } else {
      setUserWisePieData((prevData) => {
        const newOffset = prevData.datasets[0].offset.map(() => 0);
        return {
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              offset: newOffset,
            },
          ],
        }
      });
    };
  }

  useEffect(() => {
    if (selectedUserDailyUpdate && selectedUserDailyUpdate?.length > 0) {
      const labels = selectedUserDailyUpdate.map((item) => item.project_name);
      const totalTimes = selectedUserDailyUpdate.map((item) => item.total_time / 60);
      const sumTotalHours = totalTimes.reduce((acc, curr) => acc + curr, 0);

      const numProjects = selectedUserDailyUpdate.length;
      const colorPalette = chroma
        .scale(['#000000', '#f0f0f0'])
        .mode('lab')
        .colors(numProjects);

      setUserWisePieData({
        labels: labels,
        datasets: [
          {
            label: 'Total Time (Hours)',
            data: totalTimes,
            backgroundColor: colorPalette,
            borderColor: colorPalette,
            borderWidth: 1,
            offset: new Array(numProjects).fill(0),
          },
        ],
        totalHours: sumTotalHours,
      });
    }
  }, [selectedUserDailyUpdate]);


  const [isDepartment, setIsDepartment] = useState(true);
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, '0')}H ${String(remainingMinutes).padStart(2, '0')}Min`;
  }

  const onExpandedChange = (id) => {
    if (expanded.includes(id)) {
      setExpanded((prev) => {
        let temp = prev;
        temp = temp.filter((val) => val !== id);
        return temp;
      });
    } else {
      setExpanded((prev) => {
        let temp = prev;
        temp = [...temp, id];
        return temp;
      });
    }
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderRadius: "10px",
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .1)",

    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255, 255, 255, .05)",
    }),
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(3),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "rgba(0, 0, 0, .02)",
  }));


  const UserComponent = ({ card, idx }) => {
    console.log("object cardssssssssssssssssssssssssssssssss", idx);
    const [pieChartData, setPieChartData] = useState({
      labels: [],
      datasets: [
        {
          label: 'Total Time (Hours)',
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          offset: [],
        },
      ],
    });


    const handleChartHover = (event, elements) => {
      if (elements?.length > 0) {
        const index = elements[0].index;
        setPieChartData((prevData) => {
          const newOffset = prevData.datasets[0].offset.map((offset, i) => (i === index ? 40 : 0));
          return {
            ...prevData,
            datasets: [
              {
                ...prevData.datasets[0],
                offset: newOffset,
              },
            ],
          };
        });
      } else {
        setPieChartData((prevData) => {
          const newOffset = prevData.datasets[0].offset.map(() => 0);
          return {
            ...prevData,
            datasets: [
              {
                ...prevData.datasets[0],
                offset: newOffset,
              },
            ],
          }
        });
      };
    }

    useEffect(() => {
      if (card.projectAnalysis && card.projectAnalysis?.length > 0) {
        const labels = card.projectAnalysis.map((item) => item.project_name);
        const totalTimes = card.projectAnalysis.map((item) => item.total_time / 60);
        const sumTotalHours = totalTimes.reduce((acc, curr) => acc + curr, 0);

        const numProjects = card.projectAnalysis.length;
        const colorPalette = chroma
          .scale(['#000000', '#f0f0f0'])
          .mode('lab')
          .colors(numProjects);

        setPieChartData({
          labels: labels,
          datasets: [
            {
              label: 'Total Time (Hours)',
              data: totalTimes,
              backgroundColor: colorPalette,
              borderColor: colorPalette,
              borderWidth: 1,
              offset: new Array(numProjects).fill(0),
            },
          ],
          totalHours: sumTotalHours,
        });
      }
    }, [card]);

    return (
      <Box mt={3} >
        <Accordion
          expanded={!expanded?.includes(card?.departmentId)}
          onChange={() => {
            onExpandedChange(card?.departmentId);

          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Business color="primary" sx={{ fontSize: 22, mr: 1 }} />
            <Typography
              variant="h4"
              color={"primary"}
              sx={{ fontWeight: 700 }}
            >
              {card?.department}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box mb={3} width={"100%"} display={"flex"} justifyContent={"flex-end"}>
              <Button sx={{ ml: 2, width: "8vw", height: "5vh" }} variant='contained' onClick={() => onExportClick(card?.departmentId)}>
                <Typography variant="body1" color="white">Export</Typography>
              </Button>
            </Box>
            <Grid container spacing={3} >
              {card?.users?.length > 0 ? (
                card?.users?.map((data, index) =>
                  UserProjectsCard(data, index, idx, formatTime, card)
                )
              ) : (
                (
                  <CenteredBox display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"30vh"}>
                    <Typography variant="h4" color="grey">
                      No Data Found.
                    </Typography>
                  </CenteredBox>
                ))}
            </Grid>



            {(card?.projectAnalysis?.length > 0) && (
              <Box mt={4} sx={{ borderTop: "2px solid #DDDDDD" }}>

                <Box p={2} mt={2}>
                  <Typography variant='h3' color={"primary"}>Department-Wise Project Report</Typography>
                </Box>

                <Box p={4} display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"flex-start"} >
                  <Box >
                    {card?.projectAnalysis?.map((item, index) => (
                      <Box key={index} display="flex" alignItems="center" >
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: pieChartData.datasets[0].backgroundColor[index],
                            marginRight: 1,
                          }}
                        />
                        <Typography variant="body1" fontWeight={"bold"}>
                          &nbsp;{item?.project_name} &nbsp;- &nbsp;{formatTime(item?.total_time)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box width={350} height={350}>
                    <Pie
                      data={pieChartData}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: true,
                            callbacks: {
                              label: function (tooltipItem) {
                                const totalMinutes = tooltipItem.raw * 60;
                                const hours = Math.floor(totalMinutes / 60);
                                const minutes = totalMinutes % 60;
                                return ` Total Hours : ${String(hours).padStart(2, '0')}H ${String(minutes).padStart(2, '0')}Min`;
                              },
                            },
                          },
                        },
                        onHover: handleChartHover,
                      }}
                    />
                  </Box>
                  <Box></Box>
                </Box>


              </Box >

            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  const renderAllUsers = () => {
    return dailyupdateWithDepartment?.length > 0 ? (
      dailyupdateWithDepartment?.map((card, idx) => <UserComponent card={card} idx={idx} />)
    ) : (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        mt={3}
        minHeight={"15vh"}
      >
        {!loading ? 
        (<Typography
          variant="h4"
          sx={{
            color: "#999",
            textAlign: "center",
          }}
        >
          No Users.
        </Typography>) :
        (<CircularProgress/>)
        }
      </Box>
    );
  };

  return (
    <>
      <Box p={2} height={"100%"} width={"100%"}>

        <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Typography variant='h4' sx={{fontWeight:"bold"}} color={"primary"}>Employee-Wise Project Details</Typography>
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <ButtonGroup disableElevation variant="outlined" sx={{ height: "5vh" }}>
              <Button
                onClick={() => setIsMonth(true)}
                variant={isMonth ? "contained" : "outlined"} // Change variant based on state
              >
                <Typography variant="body1">Month</Typography>
              </Button>
              <Button
                onClick={() => setIsMonth(false)}
                variant={!isMonth ? "contained" : "outlined"} // Change variant based on state
              >
                <Typography variant="body1">Year</Typography>
              </Button>
            </ButtonGroup>

            {selectedUser && <Button sx={{ ml: 2, width: "8vw", height: "5vh" }} variant='contained' onClick={onExportClick}>
              <Typography variant="body1" color="white">Export</Typography>
            </Button>}

          </Box>
        </Box>

        {isMonth ? (
          <Box mt={4} id="custom-day-picker">
            <CustomMonthPicker date={date} setDate={setDate} />
          </Box>
        ) : (
          <Box mt={4} id="custom-day-picker">
            <CustomYearPicker date={date} setDate={setDate} />
          </Box>
        )}

        {!loading ? (selectedUser) ? (
          // SelectedUserProjectsCard(selectedUser, formatTime, selectedUserDailyUpdate)

          <Box width={"100%"} display={"flex"} justifyContent={"space-around"} mt={4}>
            <Box
              sx={{
                flex: 1,
                // minHeight: "20vh",
                maxHeight: "100%",
                // maxWidth: "30vw",
                // minWidth: "20vw",
                overflowY: "auto",
                scrollbarColor: "#DDDDDD",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {selectedUserDailyUpdate?.length > 0 ? (
                <>
                  <Box width={"100%"} display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} flexDirection={"row"} >
                    <Box width={"30vw"}>
                      <TableContainer component={Paper}>
                        <Table stickyHeader size='small' >
                          {/* Table Header */}
                          <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <TableRow >
                              <TableCell
                                sx={{
                                  width: "50%",
                                  backgroundColor: "#000",
                                  color: "#fff",
                                }}
                              >
                                Project
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  width: "100%",
                                  backgroundColor: "#000",
                                  color: "#fff"
                                }}
                              >
                                Hours Worked
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {/* Table Body */}
                          <TableBody >
                            {selectedUserDailyUpdate?.map((project, index) => (
                              <TableRow key={index} >
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    borderRight: "1px solid #DDDDDD",
                                  }}
                                >
                                  {project?.project_name}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    width: "100%",
                                  }}
                                >
                                  {formatTime(project?.total_time)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box>
                      <Pie
                        data={userWisePieData}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              display: false,
                            },
                            tooltip: {
                              enabled: true,
                              callbacks: {
                                label: function (tooltipItem) {
                                  const totalMinutes = tooltipItem.raw * 60;
                                  const hours = Math.floor(totalMinutes / 60);
                                  const minutes = totalMinutes % 60;
                                  return ` Total Hours : ${String(hours).padStart(2, '0')}H ${String(minutes).padStart(2, '0')}Min`;
                                },
                              },
                            },
                          },
                          onHover: handleChartHover,
                        }}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#999",
                      mt: 2,
                    }}
                  >
                    No Projects Found.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          renderAllUsers()
        ) : (
          renderAllUsers()
        )}

        {!selectedUser &&
          fetchMoreloading &&
          !loading &&
          departments.length > 0 && (
            <CenteredBox sx={{ minHeight: "100px" }}>
              <CircularProgress />
            </CenteredBox>
          )}


      </Box >
    </>
  );
};

export default EmployeeProjectAnalyticalReportUi;