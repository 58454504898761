import { Box, Button, Typography } from "@mui/material"
import { Add } from "@mui/icons-material"

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AddDocumentsController from "./AddDocumentsController";
import DataTable from "../../components/tables/DataTable";
import { USER_ROLES } from "../../utils/constants";
import { fetchVersiones } from "../../apis/version.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import CustomInput from "../../components/inputs/CustomInput";

const DocumentsUI = ({ setState, filters, SetVersionId, setFilters, callBack, loading, state, columns }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state);
    const addholiday = useCallback(() => {
        dispatch(openModal({
            title: "Add Document",
            component: <AddDocumentsController callBack={callBack} />, size: 'sm'
        }))
    }, [])
    return <>

        <Box m={3}>
            <Box>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "40px",
                    color: "#0D0D0D"
                }}>Documents
                </Typography>
            </Box>
            {user.user.data.role === USER_ROLES.hr || user.user.data.role === USER_ROLES.admin
                ? < Box sx={{ width: "100%" }}>
                    < Button onClick={addholiday} sx={{
                        display: "flex",
                        height: "100%",
                        zIndex: 0,
                        textTransform: "capitalize",
                        boxShadow: "none",
                        border: "1px solid #393939",
                        width: "100%",
                        height: "56px",
                        ":hover": {
                            boxShadow: "none"
                        },

                    }}>
                        <Add color="primary" /> &nbsp;Add Document
                    </Button>
                </Box>
                : ""
            }


            <Box mt={2} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                {user.user.data.role === USER_ROLES.hr || user.user.data.role === USER_ROLES.admin ? <Box sx={{ width: "25%" }}>
                    <AsyncDropDown
                        InputComponent={(props) => (
                            <CustomInput
                                label="version"
                                placeholder="Select version"
                                {...props}
                                value={"Selected version"}
                            />
                        )}
                        label="version"

                        lazyFun={async (props) => {
                            return await fetchVersiones({ ...props, sortDirection: -1, sort: "launch_date" })
                        }}
                        OptionComponent={({ option, ...rest }) => {
                            return (
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    {...rest}
                                >
                                    <Typography ml={3} variant="h5">
                                        {option.main_version + "." + option.sub_version}
                                    </Typography>
                                </Box>
                            );
                        }}
                        onChange={async (changedVal) => {
                            SetVersionId(changedVal?._id);
                        }}
                        titleKey={`main_version`}
                        valueKey={"_id"}
                    />

                </Box> : ""}
            </Box>
            <Box sx={{ minHeight: "300px" }} mt={2}>
                <DataTable columns={columns} rows={state.result} count={state?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
            </Box>
        </Box >
    </>
}
export default DocumentsUI
