import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton, { ResetButton } from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'
import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'
import { HALF_TYPE, HALF_TYPE_OBJ, LEAVE_TYPES, USER_ROLES } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import UserSearchBar from '../../../components/inputs/SearchBar'


const RaiseEmployeeCoffUi = ({
    userId,
    onSubmit,
    loading,
    fields,
    setFields,
    disableDates, list
}) => {
    const { holiday, user } = useSelector((state) => state)
    const theme = useTheme()
    const isSmallView = useMediaQuery(theme.breakpoints.down('500px'))



    const navigate = useNavigate()
    console.log("object fields.time_duration", list.hours < 6);
    return (
        <Box p={4} mt={1} component="form" onSubmit={onSubmit}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                <Box >
                    <Typography variant='h2'>
                        Comp-Off Application
                    </Typography>
                    <Typography variant='h6' color="error">
                        {fields.err}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant='h3' color="grey.main">
                        Application Date:
                    </Typography >
                    <Typography variant='h3' ml={2}>
                        {moment().format("DD MMMM YYYY")}
                    </Typography>
                </Box>


            </Box>



            {user.data.role == USER_ROLES.hr && <><Typography variant='h3' mt={3}>
                Select User:
            </Typography>
                <Box mt={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            height: '62px',
                        }}
                    >
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <UserSearchBar
                                onChange={async (changedVal) => {
                                    setFields({
                                        ...fields,
                                        userId: changedVal ? changedVal._id : null,
                                    });
                                }}
                                titleKey={"name"}
                                valueKey={"_id"}
                                InputComponent={(params) => (
                                    <TextField
                                        placeholder={"Select User"}
                                        {...params}
                                        margin="none"
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Box></>}
            <Box sx={{ display: "flex" }}>


                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Typography variant='h3' mt={3}>
                        Comp-Off Date:
                    </Typography>


                    <Box mt={2}>
                        <Grid container spacing={2} display={"flex"} width={"100%"} alignItems={"center"} flexDirection={isSmallView ? "column" : "row"}>
                            <Grid item md={4} xs={12} sm={12} > <DesktopDatePicker
                                // minDate={moment().add(-1, "month")}
                                // shouldDisableDate={disableDates}
                                renderInput={(props) => {
                                    return <CustomInput {...props} sx={{ height: '100%' }} />
                                }}
                                inputFormat="DD-MM-yyyy"
                                // disabled={
                                //     loading ||
                                //     !(holiday.data && Array.isArray(holiday.data)) ||
                                //     holiday.loading
                                // }
                                value={fields.date}
                                onChange={(e) => {
                                    const changedVal = {}
                                    changedVal['date'] = e

                                    setFields({ ...fields, err: '', ...changedVal })
                                }}
                                type="date"
                            /></Grid>

                            <Grid item md={8} xs={12} sm={12} >
                                <RadioGroup
                                    value={fields.time_duration}
                                    onChange={(e) => {
                                        setFields({ ...fields, time_duration: e.target.value });
                                    }}
                                >
                                    <Box p={1} sx={{ display: "flex", justifyContent: "space-evenly", border: "1px solid black", borderRadius: "5px" }}>

                                        <FormControlLabel
                                            value={HALF_TYPE_OBJ.FULL_DAY}
                                            // disabled={list?.checkedOut ? list?.hours < 6 : true}
                                            control={<Radio />}
                                            label="Full Day"
                                        />
                                        <FormControlLabel
                                            value={HALF_TYPE_OBJ.FIRST_HALF}
                                            // disabled={list?.checkedOut ? list?.hours < 3 : true}
                                            control={<Radio />}
                                            label="1st Half"
                                        />
                                        <FormControlLabel
                                            value={HALF_TYPE_OBJ.SECOND_HALF}
                                            // disabled={list?.checkedOut ? list?.hours < 3 : true}
                                            control={<Radio />}
                                            label="2nd Half"
                                        />
                                    </Box>
                                </RadioGroup>

                            </Grid>
                        </Grid>

                    </Box>


                    <Typography variant='h3' mt={3}>
                        Reason:
                    </Typography>
                    <CustomInput
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) =>
                            setFields({ ...fields, err: '', remarks: e.target.value })
                        }
                        type="text"
                        multiline
                        placeholder="Write reason of leave..."
                        rows={2}
                    />


                    <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

                        <Box sx={{ display: "flex" }} mr={3}>
                            <ResetButton
                                loading={loading}
                                type="reset"
                                variant="text"
                                onClick={() => { navigate("/calendar/") }}
                                // color="primary"
                                title=" Cancel"
                            />

                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <SubmitButton

                                loading={loading}
                                type="submit"
                                variant="contained"
                                color="primary"
                                title=" Confirm Comp-Off"
                            >

                            </SubmitButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default RaiseEmployeeCoffUi