import React, { useEffect, useMemo, useState } from "react";
import { createHoliday } from "../../apis/holiday.api";
import { createBranch } from "../../apis/branch.api";
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction";
import AddDocumentUI from "./AddDocumentUI"
import { closeModal } from "../../store/actions/modalAction";
import useValidate from "../../store/hooks/useValidator";
import { createVersion } from "../../apis/version.api";
import { createdocument, getdocumentIdApi, updatedocumentApi } from "../../apis/document.api";
import { fetchDocumentDataAction } from "../../store/actions/settingsAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const AddVersionController = ({ id, callBack }) => {

    const validate = useValidate()
    const dispatch = useDispatch()
    const createBranchApi = createBranch
    const [loading, setLoading] = useState(false)
    const { settings } = useSelector((state) => state)

    const [data, SetData] = useState({
        version: [{
            main_version: "",
            sub_version: ""
        }]
    })
    const [fields, setFields] = useState({
        name: "",
        url: '',
        version: []
    })
    const validationSchemaForCreate = useMemo(() => ([
        {
            required: true,
            value: fields.name,
            field: ' Name '
        },
        {
            required: true,
            value: fields.url,
            field: 'Upload Document',
        },
        {
            required: true,
            value: fields.version.length,
            field: 'version',
        }
    ]), [fields])
    const validationSchemaForUpdate = useMemo(() => ([
        {
            required: true,
            value: fields.name,
            field: ' Name '
        },
        // {
        //     required: true,
        //     value: fields.url,
        //     field: 'Upload Document',
        // },
        {
            required: true,
            value: fields.version.length,
            field: 'version',
        }
    ]), [fields])
    const [originalDocument, setOriginalDocument] = useState({});

    const createFunction = (e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchemaForCreate)
        console.log("first create documnet")
        if (validationResponse === true) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await createdocument({ ...fields }),
                (response) => {
                    callBack()
                    setLoading(false)
                    dispatch(fetchDocumentDataAction(settings.document_filters))
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            ))
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }
    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate);
        let updatedData = { id };
        for (let field in fields) {
            if (originalDocument[field] && fields[field] && fields[field] !== originalDocument[field]) {
                updatedData[field] = fields[field];
            }
            if (!originalDocument[field]) {
                updatedData[field] = fields[field];
            }
        }
        updatedData['name'] = fields['name'];
        updatedData['action'] = 'update';
        if (validationResponse === true) {
            setLoading(true);
            dispatch(
                callApiAction(
                    async () => await updatedocumentApi(updatedData),
                    async (response) => {
                        await callBack(data, updatedData);
                        setLoading(false);
                        dispatch(callSnackBar("Updated Successfully", SNACK_BAR_VARIETNS.suceess));
                        dispatch(closeModal());
                    },
                    (err) => {
                        setLoading(false);
                        setFields({ ...fields, err });
                    }
                )
            );
        } else {
            setFields({ ...fields, 'err': validationResponse });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (id)
            updateFunction(e);
        else
            createFunction(e);
    };

    const fetchById = (id) => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await getdocumentIdApi({ id }),
                async (response) => {

                    setFields({ ...fields, ...response });
                    setOriginalDocument(response);
                    setLoading(false);
                },
                (err) => {
                    setFields({ ...fields, err });
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        if (id)
            fetchById(id);
    }, [id]);

    return <AddDocumentUI
        loading={loading}
        onSubmit={onSubmit}
        fields={fields}
        setFields={setFields}
        SetData={SetData}
        id={id}
    />
}

export default AddVersionController;