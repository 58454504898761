// import {
//   Box,
//   DialogActions,
//   Typography,

//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import CustomInput from "../../components/inputs/CustomInput";

// import SubmitButton from "../../components/button/SubmitButton";

// import FileInput from "../../components/inputs/FileInput";

// const AddDocumentUI = ({
//   loading,
//   onSubmit,
//   fields,
//   setFields,
// }) => {
//   return (
//     <>
//       <Box
//         onSubmit={onSubmit}
//         component="form"
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           overflowY: "auto",
//           position: "relative",
//         }}
//         maxHeight="100%"
//       >
//         {
//           fields.err && <Typography color="error" variant="h6">
//             {fields.err}
//           </Typography>
//         }
//         {!loading && (
//           <>
//             <Box sx={{ display: "flex", width: "100%" }}>
//               <CustomInput
//                 disabled={loading}
//                 value={fields.name}
//                 onChange={(e) => setFields({ ...fields, name: e.target.value })}
//                 type="text"
//                 label="Document Name"
//               />


//             </Box>

//             <Box sx={{ display: "flex", width: "100%" }}>
//               <FileInput

//                 sx={{ width: "100%" }}
//                 onChange={(file) => {
//                   setFields({ ...fields, url: file })
//                 }}
//                 defaults={fields.url ? [fields.url] : []}
//                 accept=".pdf"
//                 title="Upload Document*"
//                 subTitle="Only pdf files are allowed! less than 1 MB"
//               />
//             </Box>

//           </>
//         )}

//         <DialogActions>
//           <Box sx={{ float: "right", marginTop: "7px" }}>
//             <SubmitButton
//               loading={loading}
//               type="submit"
//               variant="contained"
//               color="primary"
//               title="Add"
//             >

//             </SubmitButton>
//           </Box>
//         </DialogActions>
//       </Box >
//     </>
//   );
// };

// export default AddDocumentUI;

import {
  Box,
  DialogActions,
  Typography,

  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CustomInput from "../../components/inputs/CustomInput";

import SubmitButton from "../../components/button/SubmitButton";

import FileInput from "../../components/inputs/FileInput";
import { fetchLatestVersion, fetchVersiones } from "../../apis/version.api";
import { APP_TYPES } from "../../utils/constants";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";

const AddDocumentUI = ({
  loading,
  onSubmit,
  fields,
  setFields,
  SetData,
  id
}) => {
  console.log("first fields", fields)
  return (
    <>
      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
        maxHeight="100%"
      >
        {
          fields.err && <Typography color="error" variant="h6">
            {fields.err}
          </Typography>
        }
        {!loading && (
          <>

            <Box>
              <AsyncDropDown
                defaultVal={Array.isArray(fields.version) ? fields.version : []}
                InputComponent={(props) => (
                  <CustomInput
                    label="version"
                    placeholder="Select version"
                    {...props}
                    value={"Selected version"}
                  />
                )}
                label="version*"
                multiple={true}
                lazyFun={async (props) => {
                  return await fetchVersiones({ ...props, sortDirection: -1, sort: "launch_date" })
                }}
                OptionComponent={({ option, ...rest }) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      {...rest}
                    >
                      <Typography ml={3} variant="h5">
                        {option.main_version + "." + option.sub_version}
                      </Typography>
                    </Box>
                  );
                }}
                onChange={async (changedVal) => {
                  setFields({
                    ...fields,
                    err: "",
                    // version: changedVal?.map((v) => v.main_version + "." + v.sub_version),
                    version: changedVal?.map((v) => v._id),
                  });
                  SetData({
                    version: changedVal?.map((v) => ({
                      main_version: v.main_version,
                      sub_version: v.sub_version
                    }))
                  });

                }}
                titleKey={`main_version`}
                valueKey={"_id"}
              />

            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <CustomInput
                disabled={loading}
                value={fields.name}
                onChange={(e) => setFields({ ...fields, err: "", name: e.target.value })}
                type="text"
                label="Document Name"
              />


            </Box>

            {!id && <Box >
              <FileInput

                // sx={{ width: "100%" }}
                onChange={(file) => {
                  setFields({ ...fields, err: "", url: file })
                }}
                defaults={fields.url ? [fields.url] : []}
                accept=".pdf"
                title="Upload Document*"
                subTitle="Only pdf files are allowed! less than 1 MB"
              />
            </Box>}

          </>
        )}

        <DialogActions>
          <Box sx={{ float: "right", marginTop: "7px" }}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              title="Add"
            >

            </SubmitButton>
          </Box>
        </DialogActions>
      </Box >
    </>
  );
};

export default AddDocumentUI;
