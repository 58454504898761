import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { POLICY_TYPE } from "../../utils/constants";

import AttendancePolicy from "../../assets/pdf/Attendance.pdf";
import HolidayList from "../../assets/pdf/HolidayList.pdf";
import LeavePolicy from "../../assets/pdf/LeavePolicy.pdf";
import MaternityPolicy from "../../assets/pdf/MaternityPolicy.pdf";
import PoshPolicy from "../../assets/pdf/PoshPolicy.pdf";
import EqualOpportunityPolicy from "../../assets/pdf/EqualOpportunityPolicy.pdf";
import ESDPOLICY from "../../assets/pdf/ESDPOLICY.pdf";
import HolidayList2024 from "../../assets/pdf/HolidayList2024.pdf"
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import PDFViewer from 'pdf-viewer-reactjs'; // dont remove this import because this PDFViewer provide pdf version
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { CenteredBox } from "../../components/layouts/common/boxes";
const CompanypolicyUi = ({
  state,
  setPolicyButton,
  policyButton,
  setPolicyIdGet,
  policyIdGet,
  loading,
  setLoading,
  setTopicindex,
  topicindex,
}) => {
  const [checked, setChecked] = useState(false);
  const [pdfError, setPdfError] = useState(false);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage } =
    pageNavigationPluginInstance;
  const handleChangeButton = (topicindex) => {
    // if (topicindex < state.length - 1) {
    // setPolicyButton(state[topicindex]._id);
    // }
    setPolicyIdGet(state[topicindex]._id);
    setChecked(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper sx={{ width: "85vw", height: "90vh" }}>
        <Grid container>
          <Grid item md={2.2} xs={2.2}>
            <Box
              height={"89vh"}
              sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.3)" }}
            >
              {state?.map((e, index) => (
                <Button
                  key={e._id}
                  sx={{
                    margin: "3px",
                    fontFamily: "League Spartan",
                    width: "15vw",
                    height: "8vh",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: topicindex === index ? "#EDF0F7" : "white",
                    border:
                      topicindex === index
                        ? "1px solid #2D3B67"
                        : "1px solid #F2F2F2",
                  }}
                  disabled={index !== topicindex}
                  onClick={() => {
                    setTopicindex(index);
                  }}
                >
                  <Typography variant="h6">{e.name}</Typography>
                </Button>
              ))}
            </Box>
          </Grid>

          {loading ? (
            <Grid item md={9.8} xs={9.8}>
              <Box sx={{ marginTop: "270px" }}>
                <CenteredBox>
                  <CircularProgress />
                </CenteredBox>
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item md={9.8} xs={9.8}>
                <Box>
                  <Box height={"83vh"} mb={2}>

                    <iframe
                      src={`https://docs.google.com/gview?url=${encodeURIComponent(state[topicindex]?.url)}&embedded=true`}
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                      }}
                    // onLoad={() => console.log("PDF Loaded Successfully")}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "94%",
                      height: "5vh",
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "20px",
                      alignItems: "center",
                    }}
                  >   <>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                      >
                        <Checkbox
                          checked={checked}
                          onChange={(event) => {
                            setChecked(event.target.checked);
                          }}
                        />
                        <Typography variant="h6">
                          I hereby acknowledge that I have read,
                          understood, and agree to abide by the
                          policies and procedures of the company.*
                        </Typography>
                      </Box>

                      <Box>
                        <Button
                          variant="contained"
                          onClick={() => handleChangeButton(topicindex)}
                          disabled={!checked}
                        >
                          {topicindex === state.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </Box>
                    </></Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default CompanypolicyUi;

{
  /*  plugins={[defaultLayoutPluginInstance]} method provide pdf-viewer default-layout */
}
{
  /* <Viewer fileUrl={AttendancePolicy} plugins={[defaultLayoutPluginInstance]} /> */
}

// const pluginProps = {
//     sidebarTabs: (defaultTabs) => [
//         defaultTabs[0],
//         defaultTabs[1],
//     ],
// };
//  this const defaultLayoutPluginInstance provide pdf viewer default layout
// const defaultLayoutPluginInstance = defaultLayoutPlugin(pluginProps);

// {
//   topicindex === 6 && !loading && (
//     <Grid item md={9.8} xs={9.8}>
//       <Box>
//         <Box height={"83vh"} mb={2}>
//           <Worker workerUrl={pdfjsWorker}>
//             <Viewer
//               fileUrl={AttendancePolicy}
//               plugins={[pageNavigationPluginInstance]}
//             />
//           </Worker>
//           {/* <iframe width={"100%"} height={"600px"} src={AttendancePolicy} /> */}
//         </Box>
//         <Box
//           sx={{
//             width: "94%",
//             height: "5vh",
//             display: "flex",
//             justifyContent: "space-between",
//             marginLeft: "20px",
//             alignItems: "center",
//           }}
//         >
//           <GoToLastPage>
//             {(props) => {
//               return (
//                 <>
//                   {props.isDisabled && (
//                     <>
//                       <Box
//                         display={"flex"}
//                         flexDirection={"row"}
//                         alignItems={"center"}
//                       >
//                         <Checkbox
//                           checked={checked}
//                           onChange={(event) => {
//                             setChecked(event.target.checked);
//                           }}
//                         />
//                         <Typography variant="h6">
//                           I hereby acknowledge that I have read,
//                           understood, and agree to abide by the
//                           policies and procedures of the company.*
//                         </Typography>
//                       </Box>

//                       <Box>
//                         <Button
//                           variant="contained"
//                           onClick={handleChangeButton}
//                           disabled={!checked}
//                         >
//                           Next
//                         </Button>
//                       </Box>
//                     </>
//                   )}
//                 </>
//               );
//             }}
//           </GoToLastPage>
//         </Box>
//       </Box>
//     </Grid>
//   )
// }