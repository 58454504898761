import React, { useContext, useEffect, useRef, useState } from 'react'
import EmployeeProjectAnalyticalReportUi from './EmployeeProjectAnalyticalReportUi'
import { exportProjectDataCsvApi, getDailyUpdateByUser, getDepartment, getDepartmentWiseDataApi, getDepartmentWiseTimeCalculate, getDepartmentWiseUser, getProjectAnalysisData, getUserWiseDataApi } from '../../apis/dailyupdate.api'
import { callApiAction } from '../../store/actions/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { EmployeeTaskUserContext } from './EmployeeMain'
import { SNACK_BAR_VARIETNS } from '../../utils/constants'
import fileDownload from 'js-file-download'

const EmployeeProjectAnalyticalReportController = ({ id }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state);
    const [isMonth, setIsMonth] = useState(true);

    const [fields, setFields] = useState({
        err: "",
        department: "",
    })
    const [departmentData, setDepartmentData] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [users, setUsers] = useState([]);
    const [data, setData] = useState([])
    const departmentId = departments?.map((item) => item?._id?._id)
    console.log("original data");

    const [dailyupdateWithDepartment, setDailyupdateWithDepartment] = useState([]);
    const [date, setDate] = useState(moment());
    const [leaveOnDate, setLeaveOnDate] = useState([]);
    const abortControllerRef = useRef(null);
    const [expanded, setExpanded] = useState([]);
    const [isLwpData, setIsLwpData] = useState([]);
    const [isCompanyHoliday, setIsCompanyHoliday] = useState([]);
    const [debouncedDate, setDebouncedDate] = useState(date);
    const [selectedUserDailyUpdate, setSelectedUserDailyUpdate] = useState([]);
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
    });
    const [fetchMoreloading, setFetchMoreloading] = useState(false);
    const selectedUser = useContext(EmployeeTaskUserContext);
    console.log("object selectedUsersssssssssssssss", selectedUser);

    // const fetchProjectsByDeptId = () => {
    //     setLoading(true)
    //     if (fields.department !== "") {

    //         dispatch(callApiAction(
    //             async () => await getProjectAnalysisData({ ...filters, department: dailyupdateWithDepartment.map((item) => item?.departmentId) }),
    //             (response) => {
    //                 setDepartmentData(response)
    //                 console.log("object response", response);
    //                 setLoading(false)
    //             },
    //             (err) => {
    //                 setLoading(false)
    //             }
    //         ))
    //     } else {
    //         setLoading(false)
    //     }
    // }

    // useEffect(() => {
    //     fetchProjectsByDeptId()
    // }, [departments])


    // const fetchDepartmentWiseUser = () => {
    //     setLoading(true)
    //     if (fields.department !== "") {

    //         dispatch(callApiAction(
    //             async () => await getDepartmentWiseUser({}),
    //             (response) => {
    //                 setUsers(response)
    //                 console.log("object userssss response", response);
    //                 setLoading(false)
    //             },
    //             (err) => {
    //                 setLoading(false)
    //             }
    //         ))
    //     } else {
    //         setLoading(false)
    //     }
    // }

    // useEffect(() => {
    //     fetchDepartmentWiseUser()
    // }, [filters])


    // const fetchDepartmentWiseTimeCalculate = () => {
    //     setLoading(true)
    //     if (fields.department !== "") {

    //         dispatch(callApiAction(
    //             async () => await getDepartmentWiseTimeCalculate({ ...filters, department: fields?.department }),
    //             (response) => {
    //                 setData(response)
    //                 console.log("object department time response", response);
    //                 setLoading(false)
    //             },
    //             (err) => {
    //                 setLoading(false)
    //             }
    //         ))
    //     } else {
    //         setLoading(false)
    //     }
    // }


    const fetchDepartment = async () => {
        try {
            const response = await getDepartment({
                parent_id: user?.data?._id,
            });
            console.log("object responsesss", response);
            setDepartments(response?.data);
        } catch (error) {
            return [];
        }
    };

    const fetchDailyUpdateForSelectedUser = () => {
        dispatch(
            callApiAction(
                async () =>
                    await getUserWiseDataApi({ selectedUser: selectedUser?._id, date, isMonth }),
                (response) => {
                    setSelectedUserDailyUpdate(response);
                },
                (err) => {
                    dispatch(callSnackBar(err, "error"));
                }
            )
        );
    };

    useEffect(() => {
        if (selectedUser) fetchDailyUpdateForSelectedUser();
    }, [selectedUser, date, isMonth]);

    useEffect(() => {
        setLoading(true);
        setFetchMoreloading(true);
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const handler = setTimeout(() => {
            setDebouncedDate(date);
        }, 100);

        return () => {
            clearTimeout(handler);
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [date]);

    useEffect(() => {
        setLoading(true);
        setFetchMoreloading(true);
        fetchDepartment();
    }, []);

    const fetchDailyUpdateByDepartment = async () => {
        const currentController = new AbortController();
        abortControllerRef.current = currentController;
        try {
            console.log("API call started ", selectedUser?._id);

            for (let id of departments) {
                const response = await getDepartmentWiseDataApi({
                    departmentId: id?._id?._id,
                    date,
                    isMonth,
                    selectedUser: selectedUser?._id
                },
                    currentController?.signal);
                const projectAnalysis = await getProjectAnalysisData({
                    department: id?._id?._id,
                    date, isMonth,
                    selectedUser: selectedUser?._id
                },
                    currentController?.signal)

                setDailyupdateWithDepartment((prev) => {
                    let temp = [
                        ...prev,
                        {
                            department: id?._id?.name,
                            users: response?.data?.users,
                            departmentId: id?._id?._id,
                            projectAnalysis: projectAnalysis?.data
                        },
                    ];
                    return temp;
                });

                if (loading) {
                    setLoading(false);
                    console.log("loading", loading);

                }

                if (
                    fetchMoreloading &&
                    departments?.indexOf(id) == departments?.length - 1
                ) {
                    setFetchMoreloading(false);
                }
            }
            if (loading) {
                setLoading(false);
            }
        } catch (error) { }
    };
    console.log("object dailyupdateWithDepartment", dailyupdateWithDepartment);


    useEffect(() => {
        const currentController = new AbortController();
        abortControllerRef.current = currentController;
        setDailyupdateWithDepartment([]);
        setExpanded([]);
        // setIsLwpData([]);
        // setIsCompanyHoliday([]);
        fetchDailyUpdateByDepartment();

    }, [departments, isMonth, debouncedDate]);


    const onExportClick = (departmentWiseId) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await exportProjectDataCsvApi({ departmentId: departmentWiseId, date, isMonth, selectedUser: selectedUser?._id }),
            (response) => {
                fileDownload(response, "employee_project_Data.xlsx")
                setLoading(false)
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            },
            true
        ))

    };


    return (
        <EmployeeProjectAnalyticalReportUi
            fields={fields}
            setFields={setFields}
            departmentData={departmentData}
            loading={loading}
            setDepartmentData={setDepartmentData}
            filters={filters}
            setFilters={setFilters}
            date={date}
            setDate={setDate}
            departments={departments}
            setDepartments={setDepartments}
            dailyupdateWithDepartment={dailyupdateWithDepartment}
            expanded={expanded}
            setExpanded={setExpanded}
            isMonth={isMonth}
            setIsMonth={setIsMonth}
            selectedUser={selectedUser}
            onExportClick={onExportClick}
            fetchMoreloading={fetchMoreloading}
            selectedUserDailyUpdate={selectedUserDailyUpdate}

        />
    )
}

export default EmployeeProjectAnalyticalReportController