import {
    AccountBalance,
    AttachMoney,
    BusinessSharp,
    Edit,
    Event,
    FileCopy,
    Info,
    LocationCityOutlined,
    LockOpen,
    PersonOutlined,
} from "@mui/icons-material";
import {
    IconButton,
    List,
    ListItemButton,
    ListSubheader,
    Popover,
    Tooltip,
    Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";
import ProfileDialog from "../../profile/ProfileDialog";
import UserCreateController from "../UserCreateController";
import { USER_UPDATE_TYPE } from "../../../utils/constants";
import UpdateUserPasswordController from "../update/UpdateUserPasswordController";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import MultipleDepartmentAddController from "../MultipleDepartmentAddController";
import ResignationDateController from "../ResignationDateController";

const UserUpdateButton = ({ params, setParams }) => {
    const id = params._id;
    const dispatch = useDispatch();
    const onClick = () => { };

    const updatePersonalDetails = () => {
        dispatch(
            openModal({
                title: "Update Personal Details",
                component: (
                    <UserCreateController
                        userId={id}
                        updateType={USER_UPDATE_TYPE.personalDetails}
                    />
                ),
                size: "lg",
            })
        );
    };

    const updateMultipleDepartment = () => {
        dispatch(
            openModal({
                title: "Update Secondary Department",
                component: (
                    <MultipleDepartmentAddController
                        title={"Update Secondary Department"}
                        userId={id}
                        updateType={USER_UPDATE_TYPE.secondaryDepartment}
                    />
                ),
                size: "sm",
            })
        );
    };

    const updateCompanyDetails = () => {
        dispatch(
            openModal({
                title: "Update Company Details",
                component: (
                    <UserCreateController
                        userId={id}
                        updateType={USER_UPDATE_TYPE.companyDetails}
                    />
                ),
                size: "lg",
            })
        );
    };
    const updateBankDetails = () => {
        dispatch(
            openModal({
                title: "Update Bank Details",
                component: (
                    <UserCreateController
                        userId={id}
                        updateType={USER_UPDATE_TYPE.bankDetails}
                    />
                ),
                size: "lg",
            })
        );
    };
    const updateSalaryDetails = () => {
        dispatch(
            openModal({
                title: "Update Salary Details",
                component: (
                    <UserCreateController
                        userId={id}
                        updateType={USER_UPDATE_TYPE.salaryDetails}
                    />
                ),
                size: "lg",
            })
        );
    };

    const updateResignationDate = () => {
        dispatch(
            openModal({
                title: "Update Resignation Date",
                component: (
                    <ResignationDateController
                        userId={id}
                        updateType={USER_UPDATE_TYPE.resignationDate}
                    />
                ),
                size: "sm",
            })
        );
    };

    const updateDocumentDetails = () => {
        dispatch(
            openModal({
                title: "Update Document Details",
                component: (
                    <UserCreateController
                        userId={id}
                        updateType={USER_UPDATE_TYPE.documents}
                    />
                ),
                size: "lg",
            })
        );
    };

    const updatePassword = () => {
        dispatch(
            openModal({
                title: "Update Password",
                component: <UpdateUserPasswordController userId={id} />,
                size: "sm",
            })
        );
    };

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <>
                    <Tooltip title={"Update Details"}>
                        <IconButton {...bindTrigger(popupState)}>
                            <Edit color="info" />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        {/* <Typography sx={{ p: 2 }}> */}
                        <List
                            sx={{
                                width: "100%",
                                maxWidth: "100%",
                                bgcolor: "background.paper",
                            }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader
                                    component="div"
                                    variant="subtitle1"
                                    //sx={(theme) => ({ color: theme.palette.text.primary })}
                                    color="text.primary"
                                    id="nested-list-subheader"
                                >
                                    <Typography variant="h5">Update Details</Typography>
                                </ListSubheader>
                            }
                        >
                            <ListItemButton onClick={updatePersonalDetails}>
                                <PersonOutlined variant={"primary.main"} />
                                &nbsp;&nbsp;Personal Details
                            </ListItemButton>
                            <ListItemButton onClick={updatePassword}>
                                <LockOpen variant={"primary.main"} />
                                &nbsp;&nbsp;Update Password
                            </ListItemButton>
                            <ListItemButton onClick={updateCompanyDetails}>
                                <LocationCityOutlined variant={"primary.main"} />
                                &nbsp;&nbsp;Company Details
                            </ListItemButton>
                            <ListItemButton onClick={updateBankDetails}>
                                <AccountBalance variant={"primary.main"} />
                                &nbsp;&nbsp;Bank Details
                            </ListItemButton>
                            <ListItemButton onClick={updateSalaryDetails}>
                                <AttachMoney variant={"primary.main"} />
                                &nbsp;&nbsp;Salary Details
                            </ListItemButton>

                            <ListItemButton onClick={updateDocumentDetails}>
                                <FileCopy variant={"primary.main"} />
                                &nbsp;&nbsp;Documents
                            </ListItemButton>

                            <ListItemButton onClick={updateMultipleDepartment}>
                                <BusinessSharp variant={"primary.main"} />
                                &nbsp;&nbsp;Secondary Department
                            </ListItemButton>

                            <ListItemButton onClick={updateResignationDate}>
                                <Event variant={"primary.main"} />
                                &nbsp;&nbsp;Resignation Date
                            </ListItemButton>
                        </List>
                        {/* </Typography> */}
                    </Popover>
                </>
            )}
        </PopupState>
    );
};
export default UserUpdateButton;