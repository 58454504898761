import { Box, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import SubmitButton from "../../components/button/SubmitButton";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { getUserByIdApi, updateResignationDateApi } from "../../apis/user.api";
import { useEffect, useState } from "react";


const ResignationDateController = ({ userId }) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState();

    const getUserResignationDate = () => {
        setLoading(true);
        dispatch(callApiAction(
            async () => await getUserByIdApi({ userId: userId }),
            (response) => {

                setUserData(response);
                console.log("response", response)
            },
            (err) => {

                dispatch(callSnackBar("Update Failed", SNACK_BAR_VARIETNS.error));
            }
        ));
    };

    useEffect(() => {
        getUserResignationDate();
    }, []);

    const date = userData?.resign_date ? new Date(userData?.resign_date) : null;

    const updateResignationDate = () => {

        setLoading(true);
        dispatch(callApiAction(
            async () => await updateResignationDateApi({ id: userId, resign_date: date }),
            (response) => {
                setLoading(false);
                dispatch(closeModal());
                dispatch(callSnackBar("Update Successful", SNACK_BAR_VARIETNS.suceess));
            },
            (err) => {
                setLoading(false);
                dispatch(callSnackBar("Update Failed", SNACK_BAR_VARIETNS.error));
            }
        ));
    };
    return (
        <>
            <Box mt={3} mb={1}>
                <DesktopDatePicker
                    label="Resignation Date"
                    inputFormat="DD/MM/YYYY"
                    value={date}
                    onChange={(date) => setUserData({ ...userData, resign_date: date })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            error={false}
                        />
                    )}

                />

            </Box>
            <Box sx={{ float: "right" }}>
                <SubmitButton
                    type="button"
                    title={" Submit"}
                    onClick={updateResignationDate}

                />

            </Box>
        </>

    )
}
export default ResignationDateController;     