import { Box, Grid, TextField } from "@mui/material";

const DurationPicker = ({ value, onChange }) => {
  const hours = Math.floor(value / 60).toString(); // Convert total minutes to hours
  const minutes = (value % 60).toString(); // Get remaining minutes

  const handleHoursChange = (e) => {
    const input = e.target.value;

    if (/^$|^0$|^(0?[1-9]|1[0-2])$/.test(input)) {
      const newHours = input === "" ? 0 : parseInt(input, 10);
      onChange(newHours * 60 + parseInt(minutes || 0, 10));
    }
  };

  const handleMinutesChange = (e) => {
    const input = e.target.value;
    if (/^$|^0$|^(0?[1-9]|[1-5][0-9])$/.test(input)) {
      const newMinutes = input === "" ? 0 : parseInt(input, 10);
      onChange(parseInt(hours || 0, 10) * 60 + newMinutes);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Hours"
            type="text"
            value={hours === "0" ? "0" : hours}
            onChange={handleHoursChange}
            inputProps={{ maxLength: 3 }} 
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Minutes"
            type="text"
            value={minutes === "0" ? "0" : minutes}
            onChange={handleMinutesChange}
            inputProps={{ maxLength: 3 }} 
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DurationPicker;
