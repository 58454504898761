import logo from "./logo.svg";

import SignInController from "./pages/signin/SignInController";
import {
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { accessToken, loggedInUser } from "./utils/helper";
import { useDispatch } from "react-redux";
import { fetchUserDetails } from "./store/actions/userReducerAction";
import { memo, useEffect, useMemo, useState } from "react";
import OneViewBox from "./components/layouts/OneViewBox";
import { center } from "./assets/css/theme/common";
import { CircularProgress } from "@mui/material";

import ProfileController from "./pages/profile/ProfileController";
import AppContainer from "./components/layouts/common/AppContainer";
import MyLeaveController from "./pages/leaves/MyLeaveController";
import routes from "./routes";
import "../src/assets/fonts/azonix-cufonfonts-webfont/style.css";
import { useSnackbar } from "notistack";
import { fetchDocumentDataAction } from "./store/actions/settingsAction";
import { USER_ROLES } from "./utils/constants";

function App() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const { user, snackBar, settings } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken.get()) {
      dispatch(
        fetchUserDetails(
          undefined,
          (res) => {
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    } else {
      setLoading(false);
    }
  }, []);
  let total_policy = true;
  if (Array.isArray(settings?.document_data)) {
    const readPolicyIds = new Set(user?.data?.policy_logs?.map(log => log?.policy_id) || []);
    const unreadPolicies = settings.document_data.filter(policy => !readPolicyIds.has(policy?._id));
    total_policy = unreadPolicies.length > 0 ? false : true;
  } else {
    console.error("document_data is not an array:", settings?.document_data);
  }
  const router = createBrowserRouter(routes(user, total_policy));
  useEffect(() => {
    if (snackBar.message) {
      enqueueSnackbar(snackBar.message, { variant: snackBar.variant });
    }
  }, [snackBar.id]);

  if (loading) {
    return (
      <OneViewBox sx={{ ...center }}>
        <CircularProgress size={50} />{" "}
      </OneViewBox>
    );
  }
  return (
    <RouterProvider
      router={router}
      fallbackElement={
        <OneViewBox sx={{ ...center }}>
          <CircularProgress size={50} />{" "}
        </OneViewBox>
      }
    />
  );
}

export default memo(App);
