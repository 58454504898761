import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Slide,
    Typography,
    TextField,
    CircularProgress,

} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CustomInput from '../../../components/inputs/CustomInput'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import SubmitButton from '../../../components/button/SubmitButton'
import AsyncDropDown from '../../../components/inputs/AsyncDropDown'
import { fetchDepartments } from '../../../apis/department'

const CreateProjectUi = ({
    loading,
    err,
    setFields,
    fields,
    onSubmit,
    isUpdate,
    id
}) => {
    return (
        <>
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    position: 'relative',
                }}
                maxHeight="100%"
            >


                {loading && (
                    <CenteredBox>
                        <CircularProgress />
                    </CenteredBox>
                )}
                {!loading && (
                    <>
                        <Box sx={{ marginTop: '10px', width: '100%' }}>
                            <Typography color={"red"}>{fields.err}</Typography>
                            <Box>
                                <Typography fontWeight={500} variant="h4">
                                    {' '}
                                    Select Department :{' '}
                                </Typography>
                            </Box>
                            <Box sx={{ marginTop: '7px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', width: '100%' }}>
                                        <AsyncDropDown
                                            defaultVal={Array.isArray(fields.department) ? fields.department : []}
                                            InputComponent={(props) => (
                                                <CustomInput
                                                    label="Department"
                                                    placeholder="Select Department"
                                                    {...props}
                                                    value={fields.department.length > 0 ? "Selected Departments" : ""}
                                                />
                                            )}
                                            label="Department*"
                                            multiple={true} // Enable multiple selection
                                            lazyFun={async (props) => {
                                                return await fetchDepartments({ ...props });
                                            }}
                                            OptionComponent={({ option, ...rest }) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                        {...rest}
                                                    >
                                                        <Typography ml={3} variant="h5">
                                                            {option.name}
                                                        </Typography>
                                                    </Box>
                                                );
                                            }}
                                            onChange={async (changedVal) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    department: changedVal.map((dept) => dept._id), // Store only _id
                                                });
                                            }}
                                            titleKey={"name"}
                                            valueKey={"_id"}
                                        />

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '10px', width: '100%' }}>
                            <Box>
                                <Typography fontWeight={500} variant="h4">
                                    {' '}
                                    Project Name :{' '}
                                </Typography>
                            </Box>
                            <Box sx={{ marginTop: '7px' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.project_name}
                                        onChange={(e) => setFields({ ...fields, err: '', project_name: e.target.value })}
                                        type="text"
                                        placeholder="Write Name..."
                                    />

                                </Box>
                            </Box>
                        </Box>
                    </>
                )}

                <DialogActions>
                    <Box sx={{ float: 'right', marginTop: '7px' }}>
                        <SubmitButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            title={id ? "Update Project" : " Add Project"}
                        >

                        </SubmitButton>
                    </Box>
                </DialogActions>
            </Box>
        </>
    )
}

export default CreateProjectUi
